import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "timi-workstation-container" }
const _hoisted_2 = { class: "form-layout" }
const _hoisted_3 = { class: "timi-display-item col-6" }
const _hoisted_4 = { class: "property-label" }
const _hoisted_5 = { class: "property-value" }
const _hoisted_6 = { class: "timi-form-item col-6" }
const _hoisted_7 = { class: "timi-form-item col-6" }
const _hoisted_8 = ["placeholder"]
const _hoisted_9 = { class: "timi-form-item col-6" }
const _hoisted_10 = { class: "timi-form-item col-6" }
const _hoisted_11 = { class: "timi-form-item col-6" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-12" }
const _hoisted_14 = { class: "timi-form-item col-4" }
const _hoisted_15 = { class: "timi-form-item col-4" }
const _hoisted_16 = { class: "timi-form-item col-4" }
const _hoisted_17 = { class: "form-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_Switch = _resolveComponent("Switch")!
  const _component_SelectSingle = _resolveComponent("SelectSingle")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_IconButton, {
      icon: _ctx.store.getters.svgIcons.arrowbackward,
      class: "go-back-btn",
      label: _ctx.$t('button.back'),
      dense: true,
      onOnClick: _ctx.handleGoBack
    }, null, 8, ["icon", "label", "onOnClick"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmitPrevent && _ctx.handleSubmitPrevent(...args)), ["prevent"]))
      }, [
        _createVNode(_component_Card, {
          title: _ctx.$t('workstation.data'),
          class: "transparent border flex"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('label.uuidWorkstation')), 1),
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.form.uuidWorkstation), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_Switch, {
                item: "formMode",
                size: "mini",
                label: _ctx.$t('workstation.nfcChipReader'),
                value: _ctx.form.nfcChipReader,
                onOnChange: _cache[0] || (_cache[0] = (value) => _ctx.form.nfcChipReader = value)
              }, null, 8, ["label", "value"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.title')), 1),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                placeholder: _ctx.$t('placeholder.title'),
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.title) = $event))
              }, null, 8, _hoisted_8), [
                [_vModelText, _ctx.form.title]
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.department')), 1),
              _createVNode(_component_SelectSingle, {
                placeholder: _ctx.$t('placeholder.selectDepartment'),
                item: {},
                options: _ctx.departments,
                value: _ctx.form.department,
                disabled: false,
                onOnChange: _cache[2] || (_cache[2] = (value)=> _ctx.form.department = value)
              }, null, 8, ["placeholder", "options", "value"])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.workarea')), 1),
              _createVNode(_component_SelectSingle, {
                placeholder: _ctx.$t('placeholder.selectWorkarea'),
                item: {},
                options: _ctx.workareas,
                value: _ctx.form.workarea,
                disabled: false,
                onOnChange: _cache[3] || (_cache[3] = (value)=> _ctx.form.workarea = value)
              }, null, 8, ["placeholder", "options", "value"])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.machine')), 1),
              _createVNode(_component_SelectSingle, {
                placeholder: _ctx.$t('placeholder.selectMachine'),
                item: {},
                options: _ctx.machines,
                value: _ctx.form.machine,
                disabled: false,
                onOnChange: _cache[4] || (_cache[4] = (value)=> _ctx.form.machine = value)
              }, null, 8, ["placeholder", "options", "value"])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t('label.workstationAllowed')), 1),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_Switch, {
                  item: "formMode",
                  size: "mini",
                  label: _ctx.$t('workstation.presenceAllowed'),
                  value: _ctx.form.presenceAllowed,
                  onOnChange: _cache[5] || (_cache[5] = (value) => _ctx.form.presenceAllowed = value)
                }, null, 8, ["label", "value"])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_Switch, {
                  item: "formMode",
                  size: "mini",
                  label: _ctx.$t('workstation.workbreakAllowed'),
                  value: _ctx.form.workbreakAllowed,
                  onOnChange: _cache[6] || (_cache[6] = (value) => _ctx.form.workbreakAllowed = value)
                }, null, 8, ["label", "value"])
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_Switch, {
                  item: "formMode",
                  size: "mini",
                  label: _ctx.$t('workstation.timeentryAllowed'),
                  value: _ctx.form.timeentryAllowed,
                  onOnChange: _cache[7] || (_cache[7] = (value) => _ctx.form.timeentryAllowed = value)
                }, null, 8, ["label", "value"])
              ])
            ])
          ]),
          _: 1
        }, 8, ["title"])
      ], 32)
    ]),
    _createElementVNode("div", _hoisted_17, [
      (!_ctx.editMode)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            text: _ctx.$t('button.addWorkstation'),
            primary: true,
            raised: true,
            onOnClick: _ctx.createWorkstation
          }, null, 8, ["text", "onOnClick"]))
        : (_openBlock(), _createBlock(_component_Button, {
            key: 1,
            text: _ctx.$t('button.save'),
            primary: true,
            raised: true,
            onOnClick: _ctx.saveWorkstation
          }, null, 8, ["text", "onOnClick"]))
    ])
  ]))
}